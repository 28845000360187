/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { useRouter } from 'next/router'
import ArticleDetail from './articles/[slug]'
import Hubs from './hubs'
import { isInclude, checkUrl } from 'src/utils/routeCheck'

interface IProps {
  data: any
  articleDetailData: any
  isLoading: any
}
const DynamicRoute: React.FC<IProps> = ({
  data,
  articleDetailData,
  isLoading,
}) => {
  const router = useRouter()
  const { tag } = router?.query
  const path = Array.isArray(tag) ? `${tag.join('')}` : `${tag}`

  if (tag?.[0]?.indexOf('-Support-Hub') !== -1) {
    return <Hubs data={data} />
  } else if (isInclude(path)) {
    const route = checkUrl(path)?.['new_url '] || ''
    if (route) {
      router.push(route)
      return null
    }
  } else if (tag !== undefined) {
    return (
      <ArticleDetail
        data={data}
        articleDetailData={articleDetailData}
        isLoading={isLoading}
      />
    )
  }

  return null // Return null for undefined tag or when no condition is met
}

// This gets called on every request
export async function getServerSideProps({ query }: { query: any }) {
  const { tag } = query
  const path = Array.isArray(tag) ? `${tag.join('')}` : `${tag}`
  let isArticle = false
  let articleDetailData = null
  let isLoading = false
  const baseURL =
    process.env.NEXT_PUBLIC_BACKEND_URL ||
    'https://strapi-beta.ditchthelabel.org'
  let endPoint =
    process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
      ? `/api/article-schemas?filters[name][$eq]=hub&populate=deep`
      : `/api/article-schemas?filters[name][$eq]=mx-hub&populate=deep&locale=es-MX`

  if (tag?.[0]?.indexOf('-Support-Hub') !== -1) {
    endPoint =
      process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
        ? `/api/article-schemas?filters[name][$eq]=hub&populate=deep`
        : `/api/article-schemas?filters[name][$eq]=mx-hub&populate=deep&locale=es-MX`
  } else if (isInclude(path)) {
  } else if (tag !== undefined && tag.length === 1) {
    isArticle = true
    endPoint =
      process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
        ? `/api/article-schemas?filters[name][$eq]=articleDetail&populate=deep`
        : `/api/article-schemas?filters[name][$eq]=mx-articleDetail&populate=deep&locale=es-MX`
  }

  // Fetch data from external API

  const url = baseURL + endPoint
  if (isArticle) {
    const articleEndPoint =
      process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
        ? `/api/articles?filters[slug][$eq]=${tag[0]}&populate[author][populate][0]=Picture&populate[images][populate][0] = url&populate[brandLogo][populate][0] = url&populate[tags][populate][0] = topic &populate[article_ratings][populate]=*&populate[comments][populate][0]=id&locale=en`
        : `/api/articles?filters[slug][$eq]=${tag[0]}&populate[author][populate][0]=Picture&populate[images][populate][0] = url&populate[brandLogo][populate][0] = url&populate[tags][populate][0] = topic &populate[article_ratings][populate]=*&populate[comments][populate][0]=id&locale=es-MX`
    const articleUrl = baseURL + articleEndPoint
    isLoading = true
    const response = await fetch(articleUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    articleDetailData = await response.json()

    isLoading = false
  }
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  const data = await res.json()

  // Pass data to the page via props
  return { props: { data, articleDetailData, isLoading } }
}

export default DynamicRoute
