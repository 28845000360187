import urls from './urls.json';


function isInclude(path:string){
    return urls.some((curr) => curr.old_url.split("/").join("") ===(path) )
}

 function checkUrl(path:string) {
   return urls.find((curr) => curr.old_url.split("/").join("") ===(path))
 }

 export {isInclude, checkUrl}
