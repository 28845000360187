/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Grid } from '@mui/material'
import algoliasearch from 'algoliasearch'
import ArticleList from 'src/components/pages/article/articleList'
import ConfidentialSupport from 'src/components/shared/modules/confidentialSupport'
import TagContainer from 'src/components/pages/hub/tagContainer/TagContainer'
// import MeetNewFriend from 'src/components/shared/modules/meetNewFriends/MeetNewFriends'
// import { useGetPageByNameQuery } from 'src/store/ApiCall/pagesAPI'
// import NotAlone from 'src/components/pages/hub/notAlone/NotAlone'
// import { HubNotAloneSlider } from 'src/types/store'
import { checkSearchQuery, preprocessAllFeaturedArticles } from 'src/utils'
import FeaturedArticle from 'src/components/pages/article/Bullying/Bullying'
import {
  useGetAllFeaturedArticlesQuery as featuredArticlesQuery,
  useLazyGetLazyFavouriteArticleIdByUserQuery,
} from 'src/store/ApiCall/articleAPI'
import { FeaturedArticleProps } from 'src/types'
import useStyles from 'src/styles/pages/hub/Style'
import HubHeroHeader from 'src/components/shared/modules/heroHeader/HubHeroHeader'
import MeetNewFriends from 'src/components/shared/modules/meetNewFriends/MeetNewFriends'
import Skeleton from 'src/components/shared/modules/skeleton'
import HeadTag from 'src/components/layout/head'
import { getCookie } from 'cookies-next'
import { v4 as uuid } from 'uuid'
import { MeetNewFriend } from 'src/types/components/shared/atom'

/* eslint-disable react-hooks/exhaustive-deps */

const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP || '',
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
)

let idxName = 'search_articles'
if (typeof window === 'object' && window.location.href.includes('mx')) {
  idxName = 'mx_search_articles'
}
const searchIndex = client.initIndex(idxName)

interface IConfidentialSupport {
  heading: string
  para: string
  btnDetail: { content: string; href: string; id: number }[]
}

interface IProps {
  data?: any
}

const Hubs: React.FC<IProps> = ({ data }) => {
  const { classes } = useStyles()
  const router = useRouter()
  const [zenMode] = useState(false)
  const [query, setQuery] = React.useState<string>('')
  const [tagId, setTagId] = React.useState<number | null>(null)
  const { tag } = router?.query
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loggedInUser, setLoggedInUser] = React.useState<any>()
  const anonymousUserToken = getCookie('anonymousUserToken')
  const unique_id = uuid()

  const userToken: string | null =
    typeof window === 'object' ? localStorage.getItem('dtl_token') : null

  // if (tag?.indexOf('-Support-Hub') === -1) {

  //   return <><ArticleDetail></ArticleDetail></>
  // }

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON = localStorage.getItem('dtl_token')

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
        // setSkip(false)
      } else {
        setLoggedInUser(-1)
        // setSkip(true)
      }
    }
  }, [])
  const [getFavArticleId, { data: favArticleId }] =
    useLazyGetLazyFavouriteArticleIdByUserQuery()

  useEffect(() => {
    if (loggedInUser && loggedInUser != -1) {
      getFavArticleId(loggedInUser)
    }
  }, loggedInUser)
  const [isHash, setIsHash] = React.useState(false)

  const [featuredArticle, setFeaturedArticle] =
    useState<FeaturedArticleProps | null>()
  const [confidentialContent, setConfidentialContent] =
    useState<IConfidentialSupport>()
  const [meetNewFriendContent, setMeetNewFriendContent] =
    useState<MeetNewFriend>()
  // const [notAloneContent, setNotAloneContent] = useState<HubNotAloneSlider>()

  // const { data } = useGetPageByNameQuery('hub')
  const { data: featuredArticlesData } = featuredArticlesQuery()
  const [articleHeading, setArticleHeading] = React.useState<string>()

  function getSpecificPartOfString(str: string) {
    const supportHubIndex = str.indexOf('-Support-Hub')
    if (supportHubIndex === -1) {
      return str
    }
    const specificPart = str.slice(0, supportHubIndex).trim()

    return specificPart
  }

  React.useEffect(() => {
    if (tag && tag.length) {
      if (typeof tag[0] == 'string') {
        setQuery(getSpecificPartOfString(tag[0]))
      } else {
        setQuery(tag[0])
      }
    }
  }, [tag])
  React.useEffect(() => {
    if (query === 'all') {
      return
    }

    if (checkSearchQuery(query)) {
      searchIndex
        .search(query, {
          attributesToRetrieve: ['id', 'title', 'image', 'tags'],
          hitsPerPage: 21,
          enablePersonalization: true,
          userToken: userToken
            ? '' + JSON.parse(userToken).id
            : anonymousUserToken && typeof anonymousUserToken === 'string'
            ? anonymousUserToken
            : unique_id,
        })
        .then(({ hits }) => {
          if (hits.length > 0) {
            return
          }
          return
        })
    }
  }, [query])

  React.useEffect(() => {
    if (!featuredArticlesData) return
    if (featuredArticlesData) {
      const featuresArticleData = preprocessAllFeaturedArticles(
        featuredArticlesData,
        query
      )
      if (featuresArticleData) {
        setFeaturedArticle({
          ...featuresArticleData,
          isLiked:
            favArticleId &&
            favArticleId.length &&
            favArticleId.find((el: number) => el === featuresArticleData.id)
              ? true
              : false,
        })
      }
    }
  }, [featuredArticlesData, query, favArticleId])

  useEffect(() => {
    if (data && data.data.length > 0) {
      const {
        confidentialSupport,
        meetNewFriend,
        // hubNotAloneSlider,
        articleHeader,
        // yourOpinionMatters,
      } = data.data[0].attributes
      if (articleHeader) setArticleHeading(articleHeader)
      if (confidentialSupport) {
        const confidentialSupportContent: IConfidentialSupport = {
          heading: confidentialSupport.heading,
          para: confidentialSupport.text,
          btnDetail: confidentialSupport.button,
        }
        setConfidentialContent(confidentialSupportContent)
      }

      if (meetNewFriend) {
        const meetContent = {
          heading: meetNewFriend.heading,
          para: meetNewFriend.text,
          btnDetail: meetNewFriend.button,
        }
        setMeetNewFriendContent(meetContent)
      }

      // if (hubNotAloneSlider) {
      //   const notAloneContent: HubNotAloneSlider = hubNotAloneSlider
      //   setNotAloneContent(notAloneContent)
      // }
    }
  }, [data])

  return (
    <>
      <HeadTag
        title={data?.data[0].attributes.metaTitle}
        description={data?.data[0].attributes.metaDescription}
      />
      <Grid>
        {tagId ? (
          <HubHeroHeader
            showBrp={
              query == 'Intimidación' || query == 'Bullying' ? true : false
            }
            tagId={tagId}
            tagName={query}
            isHash={isHash}
          />
        ) : (
          <Skeleton />
        )}{' '}
        <Grid
          container
          sx={{ background: 'white', position: 'relative', zIndex: '1' }}
          pt={0.5}
        >
          <TagContainer
            query={query}
            setTagId={(id: number) => {
              setTagId(id)
              if (loggedInUser && loggedInUser != -1) {
                getFavArticleId(loggedInUser)
              }
            }}
            setIsHash={(bool: boolean) => {
              setIsHash(bool)
            }}
          />
        </Grid>
        <Grid className={classes.lightBrownBg}>
          <Grid className={classes.noiseBg}>
            {featuredArticle ? (
              <FeaturedArticle
                zenMode={zenMode}
                articleData={featuredArticle}
              />
            ) : null}
          </Grid>
          <Grid>
            {articleHeading ? (
              <ArticleList
                content={articleHeading}
                zenMode={zenMode}
                tagQuery={query}
              />
            ) : (
              ''
            )}
          </Grid>
          {confidentialContent ? (
            <Grid>
              <ConfidentialSupport
                zenMode={zenMode}
                content={confidentialContent}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        {/* {isBrowser && lang === 'En' ? (
          notAloneContent ? (
            <Grid>
              <NotAlone zenMode={zenMode} content={notAloneContent} />
            </Grid>
          ) : (
            ''
          )
        ) : (
          ''
        )} */}
        {meetNewFriendContent ? (
          <Grid className={classes.meetNewFriendContainer}>
            <MeetNewFriends zenMode={zenMode} content={meetNewFriendContent} />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  )
}

// This gets called on every request
export async function getServerSideProps() {
  // Fetch data from external API
  const baseURL =
    process.env.NEXT_PUBLIC_BACKEND_URL ||
    'https://strapi-beta.ditchthelabel.org'
  const endPoint =
    process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
      ? `/api/article-schemas?filters[name][$eq]=hub&populate=deep`
      : `/api/article-schemas?filters[name][$eq]=mx-hub&populate=deep&locale=es-MX`
  const url = baseURL + endPoint

  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  const data = await res.json()

  // Pass data to the page via props
  return { props: { data } }
}

export default Hubs
